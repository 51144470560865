import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Overhead Squat to a 1RM`}</p>
    <p>{`Test 1: 1 attempt, max bar muscle ups`}</p>
    <p>{`Test 2: 2 attempts, max distance HS Walk or max time HS Hold`}</p>
    <p>{`Test 3: 2 attempts, max unbroken double unders`}</p>
    <p>{`Test 4: `}<strong parentName="p">{`“Annie” `}</strong></p>
    <p>{`50-40-30-20-10 reps each of:`}</p>
    <p>{`Double Unders`}</p>
    <p>{`Sit Ups`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      